#pc1.hide {
  opacity: 0;
}


body {
  /*animation: fadebackground 3s linear;*/
  margin: 0;
  font-family: poppins, sans-serif;
  padding: 0;
  color: #fff;
  overflow-y: scroll;
  font-size: 14px;
}
.Cd{
  cursor:default!important;
}
.infob{
  font-family:  poppins, sans-serif!important;
}
.navbar-inverse .navbar-nav>li>a:focus, .navbar-inverse .navbar-nav>li>a:hover{
  color:#000;
}
/*
.logoAchat{
  width:23px;
  height:23px;
  background-image: url('./assets/beepbbb.svg');
  background-repeat: no-repeat;
  background-size: 23px;
}
.iconDecon{
  width: 30px;
  height: 22px;
  background-size: 22px;
  background-image: url('./assets/deconn.svg');
  background-repeat: no-repeat;
}

.logoaff{
  width:18px;
  height:18px;
  background-image: url('./assets/logoaff.svg');
  background-repeat: no-repeat;
}

.logofeu{
  width:17px;
  height:17px;
  background-image: url('./assets/logofeu.svg');
  background-repeat: no-repeat;
}
.logoAchatM{
  width:30px;
  height:22px;
  background-size: 22px;
  background-image: url('./assets/beepnoir.svg');
  background-repeat: no-repeat;
}

.logoaffM{
  width:30px;
  height:22px;
  background-size: 22px;
  background-image: url('./assets/logoaffM.svg');
  background-repeat: no-repeat;
}

.logofeuM{
  width:30px;
  height:22px;
  background-size: 22px;
  background-image: url('./assets/logofeuM.svg');
  background-repeat: no-repeat;
}
*/
.icoOpening{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.conaff{
 
  border-radius: 28px;
  padding: 25px 40px;
}
.conaff h2.w-700{
  
  color:#0d3885!important;
  font-size:24px;
  text-transform: capitalize;
  

}
.conaff .centerB{
  color:#1D1D1B!important;
}

.d-none{
  display: none!important;
}
.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: #000!important;
}
.linkaff{
  color:#878787;
  border: 1px solid #87878740;
  font-family:'Zen Kaku Gothic Antique';
  background: #fff;
  white-space: normal;
  text-align: center;
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
  background-color: transparent;
}
.progressB div:nth-child(1) div:nth-child(1){
background-image: #0d3885!important;
background-color:transparent!important;

}

.ant-spin-container::after{
  background: transparent!important;
}
.progressB div:nth-child(1){
background-color: #2B2B2B!important;
overflow: hidden;
}
.spinner{
  position: relative;
  margin-left: 50%;
  margin-right: 50%;
 margin-top: 15%;
}


.BtnHG {
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  padding: 10px 60px;
  min-width: 100px;
  background: transparent;
  border-radius: 50px;
  border: 1px solid #fff;
  letter-spacing: 1px;
  margin-top:20px;
  cursor: pointer;
}
.icoO{
  font-size: 22px;
  font-weight: 600;
    
}
.icoSpan{
   font-size: 20px;
    margin-bottom: 15px;
  border-radius: 17px;
  font-weight: 500;
 
  background: none;
  margin-top:20px;
  min-width:160px;
  text-align:center;
}
.centerCONT{
  display :grid;
  grid-template-columns: 1fr;
  align-items :center;
  justify-items :center; 
  align-content: center;
}
.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 5px;
  color:#0d3885;
  font-size: 16px;
}
.regis .dark_background_modal{
  padding: 35px;
}
.regis .ant-divider-horizontal.ant-divider-with-text {
  border-top-color: #ffffff33;
}
.regis .ant-input-affix-wrapper > input.ant-input {

  color: #000!important;
}
.regis input[type='text']{

  color: #000!important;
}
.regis .ant-card-bordered .ant-card-cover{
  margin: -1px;
  display: grid;
  justify-content: center;
   
}

.regis .ant-card {
  background: transparent;
  border:none!important;
}
.regis .ant-form-item-explain.ant-form-item-explain-error div {
  color: #ff4d4f!important;
  font-size: 12px;
}
.regis .ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 10%), 0 3px 6px 0 rgb(0 0 0 / 88%), 0 5px 12px 4px rgb(0 0 0 / 9%);
  padding: 10px;
}
.regis .ant-card:hover{
  border-color: transparent;
  /*box-shadow: 0 1px 2px -2px rgb(0 0 0 / 10%), 0 3px 6px 0 rgb(0 0 0 / 88%), 0 5px 12px 4px rgb(0 0 0 / 9%);*/
  box-shadow: none;
  padding: 0;
}
.ant-form-item-label {
 
  text-align: left!important;
}
/*.regis .ant-form-item-control-input-content{
  text-align: center;
}*/
.SPWD{
  color:#ff4d4f!important;
    font-size: 12px;
}
.jss1 .jss3 , .PrivateTabIndicator-colorSecondary-3 {
  background-color: #f3e600!important;
}
.MuiOutlinedInput-notchedOutline{
  border-color: transparent;
}

.formcontent .MuiOutlinedInput-notchedOutline {
  border-color: rgba(116,80,254,.7)!important;
  border: 3px solid !important;
  border-radius: 14px;
}
.formcontent input {
 
  color: #ffff!important;
}
.spaceBe .ant-form-item-control-input-content{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.spaceBe .ant-form-item-control-input-content a{
  color:rgb(135 132 132)!important;
}
.ceB .ant-form-item-control-input-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:20px;
}
.recaptchacn {
  display: flex;
  flex-direction: row;
  justify-content: center
}
.ceB2 .ant-form-item-control-input-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.totalV{

  margin-top:100px;
}
.totalV h2 {
  background-color: hsla(0,0%,100%,.05)!important;
  color: #fcd866;
  box-shadow: #fff!important;
  padding: 20px;
  border-radius: 17px;
  font-size: 22px;
  text-align: center;
}
.AntB{
  background:#0d3885;
  padding: 25px 50px;
  margin: 15px 0;
  border-radius: 10px;
  transition: all 200ms ease-in-out;
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  border-radius: 40px;
  font-size: 16px;
}
.AntB .MuiOutlinedInput-input {
  padding: 10.5px 14px;
}
.centerR .btn1 {
  border: 1px solid #fff;
  background: transparent;
  border-radius: 7px;
  padding: 10px;
}
.cBlanc div{
  color:#fff;
}
.centerR .btn1:hover {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 7px;
  padding: 10px;
  color:#229197;
}
.centerR .MuiFormControl-root, .MuiFormControl-root > div {
  display: block;
  width: auto!important;
}
.textF{
  border: 1px solid #fff;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 30px;
}
.centerR{
  display: flex;
  align-items: center;
}
.bgtok{
  background: #0d3885;
    border-radius: 28px;
    padding: 25px 40px;
    display: grid;
    align-items: center;
}
.bgtok .MuiTab-root{
  font-size: 15px;
 
}
.bgtok .achat .btn1{
  background-image: linear-gradient(90deg,#846ff4 0,#f17674 51%,#846ff4);
  border: 1px solid #fff;
  font-size: 14px;
  border-radius: 28px;
  color: #fff;
}
.bgtok .prim-color{
  color: #fcd866;
}
.MuiFormControl-root{
  margin-bottom: 15px!important;
}


.centerB button:disabled {
  color:rgba(255, 255, 255, 0.3)!important;
  background-color: rgba(239, 239, 239, 0.3 )!important;
  border-color:rgba(118, 118, 118, 0.3)!important;
}
.disabled2 {
  color:rgba(255, 255, 255, 0.3)!important;
  background-color: rgba(239, 239, 239, 0.3 )!important;
  border-color:rgba(118, 118, 118, 0.3)!important;
  cursor: no-drop;
}
.valeurTop{

  color: #fff;
    box-shadow: #fff!important;
    text-shadow: 0 0 0.2em #fff, 0 0 0.2em #fff, 0 0 0.2em #fff;
    font-size: 16px;
    padding: 5px 15px;
    border-radius: 17px;
    border: 2px solid #00b9e6;
    background-color: #00b9e6;
    margin-left:15px;
}
.btnTop:hover{
  background-color: #00b9e6;
  border-color: #fff;
  color:#1e194c;
}


.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #010000!important;
}
/*.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
  color: #00b9e6!important;

}*/

.colorL{
  color:#fcd866;
}
.centerB{

  display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}
.btnTop{

  color: #ffd300;
  padding: 5px 15px;
  border-radius: 17px;
  font-weight: 600;
  border: 2px solid #49ffff;
  font-size: 14px;
  margin-left: 10px;
  background: none;
  margin-top:20px;
  min-width:160px;
}
.blocF{
  padding:0 100px;
}
.msgTop{

  font-size: 16px;
  padding-bottom: 35px;
  margin-top: 2.5rem;
}
  


.btnReferral{

  display: flex;
  justify-content: space-around;
  /*padding: 0 0 50px 0;*/

}
.btnR{
  background: linear-gradient( 90deg ,#EF7837,#00b9e6, #ffd300);
    color: #fff;
    padding: 5px 15px;
    border-radius: 17px;
    font-weight: 600;
    min-width:255px;
    text-align: center;
    margin-bottom:1.3em ;
    
}
.sFooter {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: center;
}

.socials-media-icons a {
  color:#fff;
}

.sFooter a:hover {
  color:#1976d2!important;
}
:root {
  --borderWidth: 3px;
  --height: 15px;
  --width: 8px;
  --borderColor: #fff;
}
.Cphase{
  color: #fff ;
  font-weight:600;
}
.check {
  display: inline-block;
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
  

}
.bCheck{

  background-color: #1e194c;
   padding: 2px 10px;
}
.p1{
  display: flex;       
  flex-direction: column;  
  justify-content: center; 
  align-items: center;   
  height: 500px;
}


.pr-5{
  padding-right: 5px;
}

.BtnH{

  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 12px!important;
  text-transform: uppercase;
  padding: 5px 20px;
  min-width: 100px;
  background: #00FFFF;
  border-radius: 50px;
  
  letter-spacing: 1px;
  border:none;
}
.nav .BtnH:hover{
  
  background-color: transparent;
  border: 1px solid #00FFFF;
}
.BtnHG:hover{
  
  background-color: transparent;
  border: 1px solid rgba(116,80,254,.7);
}
/*.positionB a{
  padding: 10px 15px!important;
  
}*/

.MT{
  margin-top:5px;
}
.road-map-step ul{
  padding-left:10rem;
  padding-top: 2rem;
}
.priceS{

  
  /*box-shadow: #fff!important;
  text-shadow: 0 0 0.2em #fff, 0 0 0.2em #fff, 0 0 0.2em #fff;
  margin-left: 15px;*/
  font-size: 14px;
  font-weight:600;
}
.logoS{
  height: 43px;
  margin-bottom: 12px;
  zoom:0.9;
  
}
.logoS2{
  height: 60px;
  padding-bottom: 10px;
  
}

.logo{
  max-height: 150px;
}

.navbar-brand {
  padding: 0;
}



.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus {
  background-color: transparent!important;
  border: 1px solid #49ffff;
  color: #1d1d1b;
  border-radius: 14px;
  
}



.navbar {
  animation: fadebackground 3s linear;
  border: 0 !important;
  margin: 0;
}

.navbar-header {
  min-width: 147px;
}

footer {
  background: #0d3885;
  color: #fff;
  padding:20px 15px;
  margin-top: 25px;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fade {
  0% {opacity:0;}
  100% {opacity:1;}
}
main {
  /*animation: 1s ease-out 0s 1 slideInFromLeft;*/
  animation: fade 1s linear;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main > .content {
  flex: 1;
  padding:25px 0 0 0;
}

@media (max-width:767.99px) {
  .bonuss{
    color:#fff!important;
  }
  iframe #document html body div{
    padding:27pt 0!important;
  }
  .iframeCGU{
    width:auto!important;
  }
  .p0M{
    padding:0;
  }
  .textC{
    text-align: center;
  }
  .centerInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .copied{
    left:124px!important
  }
  .msgTd{
    margin-top: 10px;
    display: block;
  }
  
  
  .darkS {
    display: block!important;
  }
  .walletC a{
    color: #000!important;
    font-weight: 600!important;
    cursor: pointer!important

  }
  .walletC a:hover{
    color:#229197!important;
  }
  .btntoken a{
    font-size: 14px;
    color: #0d3885!important;
  }
  
  .navM .signO{
    display:none;
  }
  .navM .iconDecon {
    display:none;
  }
  .navHeaderM{
    margin-bottom: -5px!important;
    margin-top:5px!important;
  }
  .PaymentWizard-listbtn{
    flex-direction: column!important;
  }
 .tabMobile:hover{
    background:#0d3885 ;
    color:#fff;
  }
  .tabMobile{
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    padding: 10px;
    border-radius: 15px
  }
  .tabMobile div{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .tabMobile .spanB{
    font-weight: 700;
  }
 
 
  .footerMenu{
    gap:10px;
  }
 .top-nav ul{
    list-style-type: none;
    padding-inline-start: 0!important;
    gap:2px;
    margin-bottom: 0!important;
  }
  .navMo a{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .navMo img{
    padding-right: 15px;
  }
  .navMo li:first-child a{
    
    color:#0d3885;
    
  }
  .footerMenu a{
    color: #fff;
    font-size: 18px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  .footerMenu a:hover :first-child {
    background-image: url('./assets/deconnH.svg');
    background-repeat: no-repeat;

  }
  .footerMenu a:hover{
    color:#229197;
  }
  .navMo li:first-child {
    background-color: #fff;
    border-radius: 10px;
    
  }
  .navMo li:nth-child(2),.navMo li:nth-child(3),.navMo li:nth-child(4){
    border-bottom:1px solid #fff;
   
    
  }
  .navMo li:nth-child(2):hover, .navMo li:nth-child(3):hover ,.navMo li:nth-child(4):hover {
    border-bottom: 1px solid #229197;
    
  }
  .navMo li:nth-child(2):hover a, .navMo li:nth-child(3):hover a ,.navMo li:nth-child(4):hover a {
    color:#229197!important;
  }
  .navMo li:nth-child(2):hover .logoaffM{
    background-image: url('./assets/logoaffMH.svg');
    background-repeat: no-repeat;

  }
  .navMo li:nth-child(3):hover .logofeuM{
    background-image: url('./assets/logofeuMH.svg');
    background-repeat: no-repeat;

  }




.navMo li{
  padding: 10px 20px!important;
}
  
  .unitS a{
    color:#fff!important;
    font-weight:600;
  }
  
  .navMo ,.footerMenu{
    display: grid;
    justify-content: space-around;
    align-items: center;
    justify-items: stretch;
  }
  .unitS{
    display: grid;
    justify-content: space-around;
    align-items: center;
    justify-items: center;
  }
  .PaymentWizard-z1 {
    display: block!important;
  }
  .PaymentWizard-listbtn {
   
    flex-wrap: nowrap!important;
  }
  .msgTop {
    
    padding-bottom: 10px!important;
  }
  
  .icoO {
    font-size: 20px!important;
  }
  .AntBoxInfo{
    font-size:17px!important;
  }
  .AntBoxInfo > div > h3{
    font-size: 20px!important;
  }
  main > .content {
    padding: 0 0 0 0;
  }
}

.white {
  color: white !important;
}

.text-center {
  text-align: center;
}

.topcontent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding:50px 0;
  width: 980px;
  max-width: 90%;
  margin: 0 auto;
}

.trading-step a:hover {
  color: #00b9e6;
  font-weight: 500;
}
/*.pt-50{
  padding-top:50px;
}*/
.pb-30{
  padding: 15px;
}
.topcontent > img {
  width: 100%;
}

.box-ant {
  /*border: 4px solid #01414b;*/
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  flex-wrap: wrap;
  /* background-color: #01414bcc; */
  /* box-shadow: inset 1px 0px 20px 20px #01414b !important; */
  position: relative;
  overflow: hidden;
  padding: 15px;
  grid-gap: 15px;
}

.t-color{
  color: #01414b !important;
}

.t-color1 {
  color: #f63700 !important;
}

.box-ant-title {
  position: absolute;
  top: -60px;
  left: 0;
  font-size: 25px;
  color: #fff;
  font-weight: 100 !important;
}

.box-ant-title > b {
  font-weight: 700;
}

.box-ant > a {
  background-color:#fff;
  border: 0;
  border-radius: 15px;
  color: #fff;
  font-size: 20px;
  display: flex;
  height: 60px;
  width: 250px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight:600;
}
.box-ant > a:hover{
  background-color:#249EA0!important;
  border: 0;
  border-radius: 15px!important;
  color: #fff!important;
  font-size: 20px;
  display: flex;
  height: 60px;
  width: 250px;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.box-ant > span {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
}

.section {
  padding: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.c-lien{
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #31576e;
  margin-bottom: 10px;
}

.kyc-content {
  position: absolute;
  left: 0;
  width: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  color: #fff;
  background: linear-gradient(45deg, #0d3885, #fff);
}

.kyc-content > a {

  color: #fff;
  padding: 2px 15px;
  border-radius: 5px;
}



h2{
  color:#fff;
}

h2.w-700 {
  color: #1d1d1b;
  text-align: center;
  font-size: 35px;

}

.w-400 {
  font-weight: 400 !important;
}

.w-700 {
  font-weight: 700 !important;
}

.road-map-steps {
  display: flex;
  width: 850px;
  max-width: 80%;
  margin: 40px 0;
  flex-wrap: wrap;
}

.road-map-step {
  flex:1;
  border-top: 3px solid #fcd866;
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 300px;
  text-align: left;
  color: hsla(0,0%,100%,.5);
  margin-top: 66px;
}

.road-map-step:after{
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 20px;
  background-color: #fcd866;
  border-radius: 50%;
}

.road-map-step:before{
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -5px;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1000;
} 

.road-map-step > small {
  color: #fcd866;
  margin: 10px 5px 0;
  align-self: center;
  font-size: 15px;
  margin-top: 5px;
}

.road-map-step > p {
  color: #fcd866;
}

.trading-bg {
  background-color: #00b9e6;
}
/*.road-map{
  padding: 100px 0 20px 0;
}*/
.trading {
  width: 900px;
  max-width: 80%;
  margin: 0 auto;
  
}
.trading > div{
  width: 100%;
}

.trading-step {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.trading-step > span {
  height: 60px;
  width: 60px;
  border-radius: 10px;
  background-color: #1e194c;
  color: #fad961;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trading-step > div {
  flex: 1;
  margin-left: 10px;
  padding-top: 10px;
}

iframe {
  margin-top: 30px;
}

.web3modal-modal-card {
  background: #024238 !important;
}

.web3modal-modal-card {
  background: #024238 !important;
}

.web3modal-provider-container {
  background-color: #022721 !important;
  color: #fff !important;
}

.web3modal-provider-name {
  color: #fff !important;
}

.AntBoxInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  background:#0d3885;
  padding: 15px 5px;
  margin: 15px 0;
  border-radius: 10px;
  transition: all 200ms ease-in-out;
  height: 76px;
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  border-radius: 35px!important;
  font-size: 14px;
 
}

/*.AntBoxInfo:hover {
  background-color: #01b8bc;
}*/

.AntBoxInfo > span {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.AntBoxInfo > div {
  flex: 1;
  max-width: 80%;
}

.AntBoxInfo > div > h3 {
  font-size: 16px;
  margin: 4px;
  margin-left: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #fff;
  font-weight: 600;
}


.clipboard-copy-button{

  color:#fcd866!important;
  font-size:14px!important;
}


table tr td{

  color: #fff;
  font-size: 17px;
  
}

table thead tr th {
  vertical-align: bottom!important;
  border-bottom: 2px solid #e7eaf3;
  color: #fff!important;
  font-size: 18px!important;
  font-weight: 700!important;
  text-transform: capitalize!important;
  background: #1e2325!important;
}
table thead tr th:hover{
  background: #0d3885!important;
}



.MuiTableSortLabel-root:hover {
  color: #fff!important;
}
.achat {
  background-color: #156561;
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;

}

.achat h2 {
  font-size: 14px;
  margin-bottom: 15px;


}
.MuiTableCell-root{
  font-family: 'Zen Kaku Gothic Antique', sans-serif!important;
}

.achat h3 {
  font-size: 14px;
  color: #fff;
}

.formcontent {
  display: flex;
}

.formcontent > div {
  width: 100%;
}



.MuiInputBase-root input {
  font-size: 15px;
  font-weight: bold;
}

.achat .btn1 {
  background: rgba(116,80,254,.7);
  display: inline-block;
  border-radius: 5px;
  margin: 10px 0;
  border: none;
  width: 100%;
  padding: 10px;
}

.btnant {
  background-color: #1e194c;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}

.btnactions {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 15px;
  margin: 20px 0;
}

.modal1 {
  background-color: #010000;
  padding: 0;
  overflow: hidden;
  border-radius: 17px;
  border-color: transparent;
}

.ant-modal-body, .ant-modal-header, .ant-modal-title {

  border-bottom: none!important;

}
.ant-modal-header {
  
  border-radius: 20px 20px 0 0;
}
.ant-modal-body {
  
  border-radius:20px;
}
.ant-modal-content{
  border-radius: 20px;


}

.ant-modal-body h2{
  color:#000;
}
.anticon-close {
  color:#000;
}
.ant-modal-footer {

  border-radius: 0 0 20px 20px;
  border-top: 1px solid transparent!important;
}

#walletconnect-qrcode-modal{
  padding-top: 5em;
}

.MuiFormControl-root, .MuiFormControl-root > div {
  display: block;
  width: 100%;
}

.ant-spin {
  z-index: 0 !important;
}
#WEB3_CONNECT_MODAL_ID {
  z-index: 1000;
}

.section-title {
  margin: 50px 0;
}

.Coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  font-weight: bold;
  font-size: 20px;
  flex-direction: column;
}

.AntCercles {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  background-color: hsla(0,0%,100%,.05);
  padding: 25px;
}

.AntCercle {
  /*padding-top: 70px;*/
  position: relative;
  text-align: center;
  width: 20%;
}
.AntCercles svg{

  border: 1px solid #030239;
  background-color: #030239;
  border-radius: 1rem;
}

/*.AntCercle:after {
  content: '';
  position: absolute;
  top: -1px;
  left: 50%;
  width: 20px;
  height: 74px;
  background-color: #01414b;
  margin-left: -10px;
}
*/

.white #standard-number {
  color: white;
}

.visible-text {
  color: #229197;
  border: 1px solid #229197;
  display: inline-block;
  padding: 0 5px;
  margin-right: 5px;
  animation: pulse 2s infinite;
  display: none;
}
.visible-text2 {
  color: #fff;
    box-shadow: #fff!important;
    text-shadow: 0 0 0.2em #FAAB36, 0 0 0.2em #fff, 0 0 0.2em #fff;
    font-size: 40px;
    animation: pulse2 2s infinite;
    padding:0 0 20px 0;
}
.visible-text3 {
  color: #fff;
    box-shadow: #249EA0!important;
    text-shadow: 0 0 0.2em #249EA0 , 0 0 0.2em #fff, 0 0 0.2em #249EA0;
    animation: pulse2 2s infinite;
    font-style: italic;
    display: none;
  
}

@keyframes pulse2 {
  0% {
    text-shadow: 0 0 0.2em #fff, 0 0 0.2em #fff;


  }
  50% {
    text-shadow: 0 0 0.2em #249EA0 , 0 0 0.2em #249EA0;
  
  }
  100% {
    text-shadow: 0 0 0.2em #fff , 0 0 0.2em #fff;
  }
}

/*@keyframes pulse {
  0% {
    color: #fcd866;
    border: 1px solid #fcd866;
  }
  
  100% {
    color: #fff;
    border: 1px solid #fff;
  }
}

*/
.AntCercle > span {
  color: hsla(0,0%,100%,.5);
  text-align: center;
  font-size: 16px;
  display: block;
  margin-top: 20px;
  font-weight: 500;
}

.trading-wallet-box {
  background-color: #007b80;
  padding: 15px;
}

.trading-wallet-box > span {
  color: #fff;
}

.trading-wallet-box > p {
  background-color: #024238;
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding: 10px;
}

.MTablePaginationInner-root-11{
  color: #6c757e !important;
}

.parrainage-field {
  display: flex;
}
.parrainage-field button{
background-color: transparent;
  border-color: transparent;
}

.parrainage-field .MuiInputBase-root {
  max-width: 100% !important;
  background-color: transparent!important;
  line-height: 1.5715!important;
}



.parrainage-field .MuiInputBase-root input{
  color: #fff !important;
  border:none;
}
.parrainage-field .MuiInputBase-root input:hover{
  color: #fff !important;
  border:1px solid #1e194c ;
}
.btn-copy {
  background-color: #004854;
  color: #fff;
  border: 1px solid #1e194c;
  font-size: 25px;
  width: 56px;
  display: inline-block;
  position: relative;
}

.copied {
  position: absolute;
  top: 98px;
  left: 400px;
  height: 27px;
  width: 99px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: #000;
}
.PrivateNotchedOutline-root-1 {
  
  border-width: 0!important;
 
}

.btn-copy:active {
  background-color: #007b80;
}

.AntBoxInfo-WalletContract {
  cursor: pointer;
}
.MuiTableCell-paddingCheckbox:last-child {
  padding: 16px;
  padding-left: 0;
  padding-right: 4px;
}


.ant-modal-affilaiation{
  width: 90% !important;
}

.infoslist {
  display: flex;
  flex-direction: column;
  font-size: 25px;
}

.infoscontent {
  display: flex;
  flex-direction: column;
  font-size: 25px;
}

.tooltip-inner {
  max-width: 1000px !important;
  font-size: 15px;
}

.Component-horizontalScrollContainer-5 {
  overflow: visible !important;
}

.infoslist > .anim-box{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #01414b;
}

.infoslist-anim > .anim-box{
  animation: boxanim 5s forwards;
  animation-timing-function: cubic-bezier(1, 1, 0, 0);
}

.MuiTooltip-tooltip {
	font-size: 15px !important;
}




.ocean { 
  height: 5%;
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
  background: #01414b;
}

.wave {
  background: url(../src/assets/wave.svg) repeat-x; 
  position: absolute;
  top: -20px;
  width: 6400px;
  height: 20px;
  animation: wave 2s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: 6px;
  animation: wave 2s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 2s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1400px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}







input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}



.label-recevoir{
  display: block;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 500!important;
}
.label-platformFeesAmount{
  display: block;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px 0px 5px;
  color: #fff;
  font-size: 12px;
}

/*#standard-number {
  font-size: 16px;
  line-height: 6px;
  padding: 5px 20px;
  font-weight: 500;
  font-family: 'Poppins';
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}*/

.MuiTablePagination-select {
  font-size: 12px !important;
  color:#2B2B2B!important;
  font-family: 'Zen Kaku Gothic Antique', sans-serif!important;
  font-weight:600!important;
}


.navbar-inverse .navbar-nav > li > a {
  color: #1d1d1b;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

 
}



.btn-switch-network{
 

  padding: 0 10px !important;
  background-color: #1e194c;
  border: 0;
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}

.btn-switch-network:hover{
  background-color: #fff!important;
  color: #1e194c;
}
.positionT{
  padding-top: 10px!important;
  padding-bottom: 0!important;
}

.trading-step a{

  color:#fcd866;
  font-weight: 400;
  font-size: 13px;
}
.trading-step p{

  color: hsla(0,0%,100%,.5);
}
.spaceStep{
  position: absolute;
  top: -50px;
}

.dropdown-item {
  display: block;
  padding: 3px 15px;
  margin: 1px 0;
  color: #006e70;
}

.dropdown-item:hover {
  background: #00b9e6;
}

h1 {
  color: #1e194c !important;
}
.btnlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  margin: 20px 0;
}

.btnlist > a {
  padding: 15px;
    min-width: 250px;
    background-image: linear-gradient(
      90deg,#846ff4 0,#f17674 51%,#846ff4);
    border: 1px solid #fff;
   
    border-radius: 5px;
    display: inline-block;
    color: #fff!important;
    text-align: center;
    text-transform: uppercase;
    border-radius: 28px;
}

.t_and_c {
  
  margin-left: 5px;
  color: #fff;
  float: right;
}
@media (min-width:767.99px) {

  .navbar-nav{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .hiddenL{
    display: none!important;
    display: flex;
    padding: 0 5px;

  }
  .hiddenL li{
   
    padding: 0 10px;

  }
}
@media (max-width:767.98px) {
 

  .socials-media-icons {
  
    flex-direction: column!important;
}
  .MuiTypography-colorPrimary {
    color: #3f51b5;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:160px;
}

  .conaff {
    padding: 25px 15px;
}
.conaff h2.w-700 {
 
  font-size: 18px;
  background-position-y: 12px;
}
  .BtnHG{
    font-size:20px!important;
  }
 
  .trans .MuiTableCell-alignLeft  {
    text-align: left;
    min-width: 185px!important;
}
  .nav .btn1{
    border-radius: 20px;
    padding: 10px!important;
    color: #1d1d1b!important;
    font-weight: 600!important;
  }
  .nav .btn1:hover{
   
    color: #fff!important;
 
  }
  .hiddenL{
    display: flex;
    padding: 10px 5px;

  }
  .hiddenL li{
   
    padding: 0 10px;

  }
 .hiddenMo{
   display:none!important;
 }
  .kyc-content{
    font-size: 16px;
  }
  
  .totalV h2,.trading-steps{
    zoom:.9;
  }
  table{
    zoom:.9;
  }
  .sMobileP{
    margin: 20px;
    zoom: 0.9;
    display:block!important;
  }
  .AntCercles{
    padding:0 0 20px 0!important;
  }
  h2.w-700 {
   
    font-size: 20px;
}

  .logoS2 {
    height: 42px!important;
    padding-bottom: 0;
    zoom: 0.7;
}
  .navM .logo{
   width:80px !important;
  }
  .blocF{
    padding:0;
  }
  .btnR {
    background: #0d3885;
    color: #fff;
    padding: 5px 8px!important;
    border-radius: 17px;
    font-weight: 600;
    min-width: auto;
    font-size: 12px;
    margin: 0 0 1.4rem 0;
    min-width:100px;
}
  .mt-30{

    margin-top: 30px;
  }
  .sFooter {
    display: grid;
    grid-template-columns: 100%;
    font-size:15px;
}
  .road-map-step ul {
    padding-left: 0;
    padding-top: 2rem;
}
  .priceS {
    
    
   
    margin-left: 0px!important;
    font-size: 17px;
}
  .AntCercle:after{
    display: none;
  }
  
  .AntCercle {
    display: grid;
    padding-top: 25px;
    width: 51%!important;
}
  
  .navbar-header {
    padding: 5px 15px;
  }
  .infoscontent {
    font-size: 17px !important;
    text-align: center;
  }


.navMo li{
display: inline-block!important;
color:#1d1d1b;
font-weight: 600;

}
.navM{
  padding-inline-start: 0!important;
  display: flex;
    gap: 10px!important;
    zoom: .9;
    align-items: center;
    flex-direction: row;
    justify-content: center;

  }
  .navbar-toggle {
    position: relative;
    float: right;
    margin-right: 0;
    margin-top: -6px;
    background-color: transparent;
    background-image: none;
    border: 1px solid #010000!important;
    border-radius: 4px;
}
.navbar-inverse .navbar-toggle {
  border-color: transparent!important;
}
  .t_and_c {
    margin-left:0;
    color: #fff;
    float: none;
    display: block;
}
  .nav1 >li {
    position: relative;
    display: block;
    
}
.nav2 >li {
  position: relative;
  display: block;
  /*padding-right: 210px;*/
}
  footer {
   
    color: #fff;
    text-align: center;
}

  .p1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 92vh!important;
}
  .dM-none{
    display:none!important;
  }
  
  .topcontent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0!important;
    width: 980px;
    max-width: 90%;
    margin: 0 auto;
}
/*.pt-50 {
  padding-top: 60px!important;
}*/
.trading-step {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}
.nav1 >li {
  position: relative;
  display: block;
 
}

.BtnH {
  background-color: #49ffff;
  border-radius: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  min-width: 90px;
}
  }
  
  @media (min-width: 992px) { 
    .conaff h2.w-700{
      min-width: 350px;
      text-align: center;
      
    }
    
  
    .dL-none{
      display:none!important;
    }
  }


  .btnlist > a:hover{
  
  
    zoom: 1.05;
  }
  @media (max-width: 320px) { 
.BtnH{
  margin-left: 5px;
  min-width: 110px;
}
.AntCercle {
  display: block;
  padding-top: 25px;
  width: 80%!important;
}
main > .content ,.navbar{
  zoom:0.8;
}
.nav1 >li {
  position: relative;
  display: block;
  
}
.trading-step {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}
  }
/* ipadPro*/
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)  {
    .logoQ{
      height:29px;
    }
 
    .blocF {
      padding: 0!important;
  }
    .sFooter {

      display: grid;
      grid-template-columns: 46% 38% 16%;
  }
    .c-lien {
      width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #13bf58;
  }
  
  .nav1>li>a,.nav2>li>a {
    position: relative;
    display: block;
    padding: 10px 3px!important;
}
.navbar-header {
  min-width: 0!important;
}
/*.BtnH {
  background-color: #1e194c;
  border: 0;
  border-radius: 15px;
  color: #fff!important;
  font-size: 15px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding:0 10px;
  min-width: 75px;
}*/

.nav1>li,.nav2>li {
  float: left;
  margin-top: 10px!important;
}
  }
  /*ipad portrait*/
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .nav1 ,.nav2{
      gap:5px!important;
    }
    .positionT{
      margin-top: -5px;
    }
    .blocF {
      padding: 0!important;
  }
    .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form{

      zoom:0.65;
    }
 .dipad-none{
   display: none;
 }
.navbar-header {
    min-width: 0;
}

.logoQ{
  height: 22px!important;
 
}
.conaff h2.w-700{
  font-size: 22px!important;
}
.navbar-header {
min-width: 0!important;
}
.BtnH {
background-color:#49ffff;
border: 0;
border-radius: 15px;
font-size: 14px!important;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
padding:0 10px;
min-width: 75px;
}



.AntCercle {
  /* padding-top: 70px; */
  position: relative;
  text-align: center;
  width: 25%;
  margin: 5px 0;
}
.topcontent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  width: 980px;
  max-width: 98%;
  margin: 0 auto;
}
.c-lien {
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #13bf58;
}
.sFooter {
  display: grid;
  grid-template-columns: 49% 25% 26%;
}
.AntCercles {
  zoom: .9;
}

  }
  .MuiTableCell-body {
    font-size: 16px!important;
  }
  /*ipad landscape*/
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .nav1 ,.nav2{
      gap:5px!important;
    }
    .AntCercles {
      zoom: .9;
    }
    
    .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form{

      zoom:0.75;
    }
    .sFooter {
      display: grid;
      grid-template-columns: 47% 38% 17%;
  }
  }

  @media (min-width: 768px) and (max-width: 991.98px) { 
    .nav1 ,.nav2{
      gap:5px;
    }

    .btnR {
      background: linear-gradient( 90deg,#EF7837,#00b9e6, #F8AE28);
      color: #fff;
      padding: 5px 8px!important;
      border-radius: 17px;
      font-weight: 600;
      min-width: auto;
      margin: 0 5px;
  }
    .dL-none{
      display:none!important;
    }
    .AntCercle {
      /* padding-top: 70px; */
      position: relative;
      text-align: center;
      width: 25%;
      margin: 5px 0;
    }
    .topcontent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 50px 0;
      width: 980px;
      max-width: 98%;
      margin: 0 auto;
    }
    .road-map-step ul {
      padding-left: 0;
      padding-top: 2rem;
  }

    .sFooter {
      display: grid;
      grid-template-columns: 53% 23% 25%;
  }
 .c-lien {
  width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #13bf58;
}

   }


   .tx-field-tables > a{
    color: #fff;
  }
  
  .ant-tabs {
    color: #6c757e;
  }
  .ant-tabs-tab-btn:hover{
    color: #6c757e !important;
  }
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #6c757e;
  }

  .refferral-warn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-weight: bold;
    font-size: 20px;
    flex-direction: column;
  }
  .refferral-warn-contact{
    background-color: #010000;
    border-radius: 9px;
    color: #fff!important;
    padding: 5px;
    margin-top: 3rem;
  }

  .socials-media-icons>*{
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
  }
  .socials-media-icons>*:hover{
    color:#2367a0!important;
  }
  
  .socials-media-icons{
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    
  }
  .copy_rigth{
    padding-top: 5px;
  }


  .clipboard-copy-button:hover{
    cursor: pointer;
  }
  
.ResetPassword {
  margin: 30px auto;
  width: 90%;
  max-width: 485px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 5px;
  background: #e8f0fb;
}

.ResetPassword label {
  color: #000 !important;
}

.ResetPassword h1 {
  color: #000!important;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.PaymentWizard {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  flex: 1;
}

.PaymentWizard-title {
  color: #fff;
  font-size: 16px;
}

.PaymentWizard-listbtn {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.PaymentWizard-listbtn > button {
  background-color: transparent;
  border: 2px solid #fff!important;
  border: none;
  border-radius: 5px;
  padding: 10px 5px;
  color: #fff;
  min-width: 110px;
  font-size: 14px;
}

.PaymentWizard-listbtn > button.active {
  background-color: #fff;
  border: 2px solid #fff;
  color: #229197;
}

.PaymentWizard-p {
  font-size: 16px;
}

.PaymentWizard-input {
  display: flex;
 
  max-width: 200px;
  min-width: 200px;
  background: #2B2B2B;
  border-radius: 5px;
  color: #fff;
  overflow: hidden;
  align-items: center;
}



.PaymentWizard-input > input {
  border: none;
  color: #000;
  display: inline-block;
  flex: 1 1;
  max-width: 120px;
  padding: 10px;
  outline: none;
  text-align: center;
  font-weight: 600;
}


.PaymentWizard-input > .PaymentWizard-c {
  color: #fff;
  align-self: center;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PaymentWizard-btn1 {
  background-color: #fff;
  border: 2px solid #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: #229197;
  min-width: 120px;
  max-width: 150px;
  margin-top: 15px;
}

.PaymentWizard-btn1c1 {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  
}

.PaymentWizard-btn1:disabled {
  opacity: 0.5;
  color: #ccc ;
}



.PaymentWizard-input1 {
  display: flex;
  flex-direction: column;
}

.PaymentWizard-input1 input {
  border: none;
  color: #000;
  border: 1px solid transparent;
  flex: 1;
  padding: 10px 20px;
  outline: none;
  border-radius: 5px;
}
.PaymentWizard-input1 label {
  color: #fff;
  font-weight: 100;
  font-size: 14px;
}

.PaymentWizard-input1.has-error input {
  border: 1px solid #ff0a0ac2;
}

.PaymentWizard-input1.has-error label {
  color: #ff0a0ac2;
}

.PaymentWizard-btns {
  display: flex;
  gap: 15px;
}

.PaymentWizard-z1 {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.PaymentWizard-z1 small td {
  font-size: 16px !important;
  /*padding: 2px 10px;*/
}

.PaymentWizard-c-lien {
  width: 100%;
  display: flex;
  grid-gap: 20px;
  gap: 10px;
  align-items: center;
  background: #ffffff2e;
  padding: 5px;
}
.PaymentWizard-c-lien a{
color:#fff!important;
font-weight: 600;
}

.table{
  width:100%;
  box-shadow: 2px 2px 13px 4px #f0f0f0;
}
.table td{
  padding:20px 20px 0 20px;
}
.table th{
  background-color:#1e2325;
  padding:20px;
  border:1px solid #c8c8c8;
}

.table td{
  background-color:#fff!important;
  color:#1e2325;
  border-right: 1px solid #c8c8c8 ;
  border-left: 1px solid #c8c8c8 ;
  border-bottom: 0;
 
}

.MuiTableCell-body{
  background-color:#fff!important;
  color:#1e2325;
 
  border-left: 1px solid transparent ;
  border-bottom: 0!important;

}

.MuiTableCell-body:after {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid transparent;
  padding-top: 15px;
}
.pagTab{
  color: rgb(30, 35, 37);
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    padding: 30px 0 0 0;
}
.pagin{
  font-weight: 700;
}
.cer{
  background: #0d3885;
  border-radius: 50%;
  width: 1.6em;
  height: 1.6em;
  display: grid;
  align-items: center;
  justify-items: center;
  color:#fff;
  font-size:22px;
}
.MTablePaginationInner-root-7{
  gap:20px;
  color:#2B2B2B!important;
}
.MTablePaginationInner-root-7 span:nth-child(2n) {
  border-radius: 50%;
  background: #0d3885;
}
.MTablePaginationInner-root-7 span:nth-child(2n) button {
  color:#fff;
}
.MuiIconButton-root{
  padding:5px!important;
}
.MuiSvgIcon-root{
  width:1.6em!important;
  height:1.6em!important;
}
.MuiSelect-icon{
  top:0!important;
}
.MTablePaginationInner-root-7 span:nth-child(2n+1) button span svg{

  fill:#1976d2;
}
.MuiTouchRipple-root{
  position:relative!important;
}
.MuiTypography-caption{
  font-weight: 600!important;
  font-size:12px!important;
  font-family:'Zen Kaku Gothic Antique', sans-serif!important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active{
  color:#fff!important;
}
.SelectLangue{
  display: inline-block;
  position:relative;
  color:#1d1d1b;
  font-weight: 600;
  font-size:14px;
  padding: 0 3px;
  cursor: pointer;

}
.SelectLangue ul{
  list-style-type:none;
  padding-inline-start:0;
}
.SelectLangue-items{
  position: absolute;
  top: 22px;
  right: 0;
  background: #fff;
  z-index: 2;
  font-weight: 500;
  border-radius: 7px;

}
.ant-btn-primary {
  color: #fff;
  border-color: transparent;
  background: #0d3885;
  text-shadow: none;
  box-shadow: none;
  border: none;
  color: #fff!important;
  border-radius: 10px!important;
  padding: 5px 20px!important;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background:#0d3885;
  border-color:transparent;

}
.connIns{
  display: flex!important;
  flex-direction: row;
  gap: 10px;
  
}
.connIns .btn1{
  color:#fff;
  font-size: 15px;
  text-transform: none;
  font-weight: 300;
  padding: 5px 10px;
  background-color: #1976D2;
  border:1px solid #1976D2;
  border-radius: 25px;
  min-width: 122px;
  text-align: center;
}
.connIns .btn1:hover ,.connIns .btn1:focus{
  color:#1976D2!important;
  font-size: 15px;
  text-transform: none;
  font-weight: 300;
  padding: 5px 10px;
  border:1px solid #1976D2!important;
  border-radius: 25px;
  min-width: 122px;
  text-align: center;
}
/*.btntoken a {
  background: #0d3885;
  border: 1px solid #0d3885!important;
  color: #fff!important;
  border-radius: 10px!important;
  padding: 5px 15px!important;
}
.btntoken2 .a1{
  background: #0d3885;
  border: 1px solid #0d3885!important;
  color: #fff!important;
  border-radius: 10px!important;
  padding: 5px 30px!important;
}
*/
.nav>li>a>img {
  max-width: none;
  max-height: 16px;
  margin-top: -3px;
  margin-right:3px;
}
.nav1 ,.nav2{
  gap:13px;
}




/*.btnaff a{
  border: 1px solid #000;
    color: #000!important;
    border-radius: 10px!important;
    padding: 5px 15px!important;
}
.btnaff a:active, .btnaff a:focus, .btnaff a:hover {
  border: 1px solid #1976d2;
  color:#1976d2!important;
}

.btnaff a:active .logoaff, .btnaff a:focus .logoaff, .btnaff a:hover .logoaff {
  background-image: url('./assets/logoaffH.svg');
  background-repeat: no-repeat;
}

.btnfeu a{
  border: 1px solid #000;
    color: #000!important;
    border-radius: 10px!important;
    padding: 5px 15px!important;
}
.btnwhite a{
  border: 1px solid #000;
    color: #000!important;
    border-radius: 10px!important;
    padding: 5px 15px!important;
}
.btnwhite a:active,.btnwhite a:focus, .btnwhite a:hover {
  border: 1px solid #1976d2;
  color:#1976d2!important;
}
.btnfeu a:active,.btnfeu a:focus, .btnfeu a:hover {
  border: 1px solid #1976d2;
  color:#1976d2!important;
}
.btnfeu a:active :first-child, .btnfeu a:focus :first-child, .btnfeu a:hover :first-child {
  background-image: url('./assets/logofeuH.svg');
  background-repeat: no-repeat;
}
*/
.priceS:hover{
  color:#1976d2;
}
/*
.btnaff2 .a2:active, .btnaff2 .a2:focus, .btnaff2 .a2:hover {
  border: 1px solid #1976d2!important;
  color:#1976d2!important;
}
.btnaff2 .a2{
  border: 1px solid #0d3885!important;
    color: #0d3885!important;
    border-radius: 10px!important;
    padding: 5px 30px!important;
}
*/
.SelectLangue-items{
  display: none;

}

.SelectLangue:hover .SelectLangue-items{
  display: block!important;

}
.navbar-nav img{
  margin-top: -3px;
}
.SelectLangue ul li:hover {
background: #0d3885;
padding: 2px 15px;
color:#fff;
}
.SelectLangue ul li:nth-child(1):hover{
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.SelectLangue ul li:nth-child(2):hover{
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.SelectLangue ul li {
  padding: 2px 15px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  }

  .SelectLangue ul li img {
    width: 20px;
    height: 20px;
  }

.menuMobile{
  position: fixed;
  top:0;
  bottom: 0;
  right:0;
  left:0;
  z-index:999;
  background:#0d3885;
  padding:15px;
}
.headerMenu{
  display: flex!important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
.headerMenu button{
  background: transparent;
    border: none;
    font-size: 30px;
    margin-top:-10px;
}

div.msgTop.row{
  padding-bottom : 0px;
}

.status.unverified{
  color: #a1a100;
}

.status.verified{
  color: #0c1abf;
}

.status.canceled{
  color: red;
}

.fixeIcon .ant-form-item-children-icon{
  top:32%!important;
}
.ant-btn{
  border-radius: 10px;
}

.min-red{
  color: red;
}

.darkS{
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
.regisM{
  width:785px!important;
}
.metamaskS{
  display: flex;
    flex-direction: column;
    gap: 20px;
}
.metamaskS .BtnH {
  background-color: #0d3885!important;
  border-radius: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding:10px;
  min-width: 90px;
  color: #fff;
  font-weight: 500!important;


}
.navM .langb{
  display:none;
}

.error-message{
  color: red;
}
.success-message{
  color: green;
}
.info-message{
  color: #000;
}

.fixeIcon .ant-form-item-explain{
display: none!important;

}

.fixeIcon div {
  margin-bottom: 0px;
}

.icon-copy {
  color: white;
  font-size: 18px;
}
.imgSo a{
  padding-right:5px;
}
.tdV{
  display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.text-center{
  text-align: center;
}



.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

input[type=checkbox]{
  margin: 4px 10px 4px 0;
}
.siq_bR {
  bottom: 67px!important;
  right: 10px;
}
.bonuss{
  color:#1976d2;
}
.nav>li>a:focus, .nav>li>a:hover {
  text-decoration: none;
  background-color: transparent!important;
  color: #000;
}
.navbar-toggle .icon-bar {
  display: block;
  width: 16px;
  height: 2px;
  border-radius: 1px;
  background: #000;
}